.main_about {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    padding-top: 120px;
    flex-direction: column;

    @media(max-width:1040px) {
        padding-top: 40px;
    }

    .container {


        .top_about {
            display: flex;
            justify-content: space-between;

            @media(max-width:1040px) {
                flex-direction: column;
                gap: 2rem;
            }

            .left_about {
                width: 40%;

                @media(max-width:1040px) {
                    width: 100%;
                }

                .nice {
                    display: block;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-bottom: 12px;
                }

                .name {
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 1.3;
                    font-family: var(--font-inter);
                    color: var(--dark-color);

                    @media(max-width:1600px) {
                        font-size: 35px;
                    }

                    @media (max-width: 1040px) {
                        font-size: 30px;
                    }
                }

                .nice_about {
                    font-weight: 600;
                    font-size: 20px;
                    color: var(--dark-color);
                    margin-bottom: 40px;

                    @media(max-width:1600px) {
                        font-size: 13px;
                        margin-bottom: 30px;
                    }

                    @media(max-width:1040px) {
                        margin-bottom: 10px;
                    }
                }

            }

            .right_about {
                width: 60%;

                @media(max-width:1040px) {
                    width: 100%;
                }

                p {
                    margin-bottom: 30px;

                    @media(max-width:1040px) {
                        margin-bottom: 10px;
                    }
                }


                .personal_main {
                    margin-top: 14px;
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    flex-wrap: wrap;

                    .personal_content {
                        .person_title {
                            text-transform: uppercase;
                            text-decoration: underline;
                        }

                        .person_answer {
                            font-family: var(--font-inter);
                            font-weight: 700;
                            color: var(--dark-color);
                        }


                    }

                }

            }

        }

        .aboutcard_main {
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;
            margin-top: 60px;
            margin-bottom: 120px;

            @media(max-width:1200px) {
                margin-bottom: 30px;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;
            }

            @media(max-width:1040px) {
                margin-bottom: 30px;
            }

            @media(max-width:770px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
            }

            .aboutcard {
                width: 100%;
                position: relative;
                text-align: center;
                padding: 60px 20px;
                border-radius: 4px;
                overflow: hidden;

                @media(max-width:1600px) {
                    padding: 45px 20px;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 3;
                    background: rgba(255, 255, 255, .5);
                    content: '';
                    -webkit-transition: -webkit-transform .6s;
                    transition: transform .6s;
                    -webkit-transform: scale3d(3.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
                    transform: scale3d(3.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
                }

                &:hover:before {
                    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
                    transform: scale3d(3.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
                }


                .card_title {
                    font-size: 40px;
                    margin-bottom: 7px;
                    font-weight: 700;
                    line-height: 1.3;
                    font-family: var(--font-inter);
                    color: var(--dark-color);

                    @media(max-width:1600px) {
                        font-size: 35px;
                        margin-bottom: 3px;
                    }


                }

                .card_para {
                    font-weight: 500;
                    font-family: var(--font-karla);
                    text-transform: uppercase;
                }
            }

        }

    }

}