.main_navber {
  background-color: var(--white-color);
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  z-index: 1;

  .logo {
    color: var(--blue-color);
    font-family: var(--font-karla);
    font-weight: 800;
    font-size: 30px;
  }

  .icon {
    color: var(--blue-color);
  }
}

.ant-drawer-header {
  border-bottom: unset !important;
}

.main_sidenavbar {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  height: 100%;

  .sidebar_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile_pic {
      height: 118px;
      width: 118px;

      @media (max-width: 1600px) {
        width: 77px;
        height: 77px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .name {
      width: 100%;
      float: left;
      margin-top: -9px;

      h3 {
        font-weight: 700;
        line-height: 1.3;
        font-family: var(--font-inter);
        color: var(--dark-color);
      }

      span {
        position: relative;
        font-style: italic;
        font-size: 88px;
        font-family: var(--font-kristi);
        line-height: 1;
        color: var(--sign-color);
        display: block;

        @media (max-width: 1600px) {
          font-size: 50px;
        }
      }

      .back {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        font-family: var(--font-inter);
        font-weight: 700;
        font-size: 30px;
        color: var(--dark-color);
        font-style: normal;
        margin-top: 5px;
        width: max-content;

        @media (max-width: 1600px) {
          font-size: 22px;
        }
      }
    }
  }

  .sidebar_centre {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1600px) {
      gap: 9px;
      // padding-top: 70px;
      // padding-bottom: 180px;
    }

    .menu_active {
      cursor: pointer;
      transition: 0.3s ease-in-out;
      font-weight: 500;
      color: var(--blue-color);

      @media (max-width: 1600px) {
        font-size: 16px;
      }
    }

    .menu {
      cursor: pointer;
      transition: 0.3s ease-in-out;
      font-weight: 500;

      &:hover {
        color: var(--blue-color);
      }

      @media (max-width: 1600px) {
        font-size: 16px;
      }
    }
  }

  .sidebar_bottom {
    .social_icon {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: center;

      .icon_back {
        width: 40px;
        height: 40px;
        display: inline-block;
        position: relative;
        border-radius: 100%;
        background-color: var(--sign-color);
        color: var(--dark-color);
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1600px) {
          width: 36px;
          height: 36px;
        }

        .icons {
          font-size: 20px;

          @media (max-width: 1600px) {
            font-size: 16px;
          }
        }
      }
    }

    p {
      padding-left: 50px;
      padding-right: 50px;
      text-align: center;
      padding-top: 30px;

      @media (max-width: 1600px) {
        font-size: 16px;
        padding-top: 10px;
      }
    }
  }
}
