.main_portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-top: 120px;

  @media(max-width: 1040px) {
    padding-top: 40px;
  }

  .left_port {
    .port {
      width: 100%;
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 12px;
    }

    .port_title {
      font-size: 40px;
      font-weight: 800;
      line-height: 1.3;
      font-family: var(--font-inter);
      color: var(--dark-color);

      @media(max-width:1600px) {
        font-size: 35px;
      }

      @media(max-width:1040px) {
        font-size: 30px;
      }
    }
  }

  .container {

    .top_port {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 40px;

      @media(max-width:750px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.5rem;
      }

      .right_port {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;


        .icon {
          width: 49px;
          height: 49px;
          border-radius: 100%;
          border: 1px solid #130f49;
          display: inline-block;
          margin-right: 12px;
          position: relative;
          transition: all 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;

          &:hover {
            background-color: #130f49;
            color: white;
          }
        }
      }
    }

    .portfolio_card {
      width: 383.33px;
      height: 548.14px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media (max-width: 1600px) {
        width: 300px;
        height: 442.38px;
      }

      .card_content {
        .porfolio_img {
          height: 445.14px;
          width: 383.33px;
          transition: 0.3s ease-in-out;
          overflow: hidden;

          @media (max-width: 1600px) {
            width: 300px;
            height: 348.38px;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s ease-in-out;
          }
        }

        .sub_title {
          margin-top: 25px;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            color: var(--yellow-color);
          }
        }

        .portcard_title {
          font-weight: 600;
          font-size: 24px;
          color: #130f49;
          line-height: 1.3;
          font-family: var(--font-inter);
          position: relative;
          width: fit-content;
          cursor: pointer;

          @media (max-width: 1600px) {
            font-size: 21px;
          }

          &::after {
            content: "";
            width: 0;
            height: 1.5px;
            position: absolute;
            left: auto;
            right: 0;
            bottom: 0;
            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
            background: currentColor;
          }

          &:hover::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }
      }
    }

    .btn_sec {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
    }
.partner_main{
padding-top:40px;
  .partners_inner {
    width: 100%;
    float: left;
    clear: both;
    overflow: hidden;
    border-radius: 10px;
    margin-top:40px;

    ul {
      margin: -2px -10px -2px -2px;
      list-style-type: none;
      padding-top: 2px;
      float: left;
      padding-left: 2px;
      min-width: calc(100% + 12px);

      @media(max-width:740px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    li {
      margin: 0;
      float: left;
      width: 25%;
      border: 2px solid #e7e7e7;
      text-align: center;
      height: 180px;
      line-height: 180px;
      position: relative;
      margin-top: -2px;
      margin-left: -2px;
      overflow: hidden;

      @media(max-width:740px) {
        width: 100%;
        border: 1px solid #e7e7e7;
      }
    }

    .list_inner {
      width: 100%;
      height: auto;
      clear: both;
      float: left;
      opacity: 0.8;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      text-align: center;
      line-height: 180px;
      list-style-type: none;
    }

    img {
      max-width: 88%;
      max-height: 100px;
    }

    .anchor_link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }
  }
}

    .main_testimonial {
      width: 100%;
      float: left;
      background-color: #fff5f6;
      padding: 100px 150px 105px 100px;
      margin-bottom: 120px;
      margin-top: 80px;

      @media(max-width: 1600px) {
        padding: 70px 120px 85px 70px;
        margin-bottom: 60px;
      }

      @media(max-width: 1040px) {
        padding: 40px 70px 70px 40px;
      }

      @media(max-width: 550px) {
        padding: 20px 20px 70px 20px;
      }

      @media(max-width: 740px) {
        margin-top: 40px;
      }

      .testi_subtitle {
        width: 100%;
        font-weight: 500;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 12px;
        text-align: center;
      }

      .testi_title {
        font-size: 40px;
        font-weight: 800;
        line-height: 1.3;
        font-family: var(--font-inter);
        color: var(--dark-color);
        text-align: center;
        margin-bottom: 43px;

        @media(max-width: 1600px) {
          font-size: 35px;
        }

        @media(max-width: 1040px) {
          font-size: 30px;
          margin-bottom: 16px;
        }
      }

      .test_1 {
        max-width: 960px;

        .comment {
          font-size: 24px;
          line-height: 40px;
          font-family: var(--font-inter);
          margin-bottom: 33px;
          text-align: center;

          @media(max-width: 1600px) {
            font-size: 18px;
          }

          @media(max-width: 1040px) {
            font-size: 16px;
            margin-bottom: 20px;
          }

          @media(max-width: 550px) {
            font-size: 15px;
            line-height: 30px;
          }
        }

        .short {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;

          .image {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 100%;
          }

          .main {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 100%;

            &::before {
              position: absolute;
              content: "";
              top: -5px;
              bottom: -5px;
              left: -5px;
              right: -5px;
              border-radius: 100%;
              background-color: #fff;
              z-index: -1;
            }
          }

          .detail {
            text-align: left;
            padding-left: 18px;

            h3 {
              font-size: 30px;
              font-weight: 500;
              line-height: 1.3;
              font-family: var(--font-inter);
              color: var(--dark-color);

              @media(max-width: 1600px) {
                font-size: 25px;
              }

              @media(max-width: 1040px) {
                font-size: 20px;
              }
            }
          }
        }

        .job {
          text-align: center;
        }
      }
    }
  }
}

.slick-dots {
  margin-top: 39px !important;
  bottom: unset !important;
  margin-bottom: 130px;

  @media(max-width: 1600px) {
    margin-top: 10px !important;
  }
}

.slick-dots li button:before {
  font-size: 18px !important;
  color: #cec5c9;

  @media(max-width: 1040px) {
    font-size: 14px !important;
  }
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #5d3bee !important;
}