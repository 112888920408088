@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

* {
  box-sizing: border-box;
}

.main_blogs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  background-color: #fff;
width: 100%;

  @media(max-width: 1040px) {
    padding-top: 40px;
  }

  .left_port {
    margin-bottom: 30px;

    @media(max-width: 1600px) {
      margin-bottom: 20px;
    }

    .port {
      width: 100%;
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 12px;

      @media(max-width: 1600px) {
        margin-bottom: 8px;
      }
    }

    .port_title {
      font-size: 40px;
      font-weight: 800;
      line-height: 1.3;
      font-family: var(--font-inter);
      color: var(--dark-color);

      @media(max-width: 1600px) {
        font-size: 35px;
      }

      @media(max-width: 1040px) {
        font-size: 30px;
      }
    }
  }

  .main_blog_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1.5rem;

    @media(max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media(max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 1040px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media(max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    }

    .blog_card {
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
      width: 100%;
      position: relative;
      border-radius: 12px;
      // height: 417px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      @media(max-width: 650px) {
     width: 330px;
     height: 417px;
     
        }
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transform: scale(1.05, 1.05);
      }

      &:hover .card__img--hover {
        height: 100%;
        opacity: 0.3;
      }

      &:hover .card__info {
        background-color: transparent;
        position: relative;
      }

      .card__img {
        visibility: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 235px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .card__img--hover {
        transition: 0.2s all ease-out;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        position: absolute;
        height: 235px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        top: 0;
      }

      .card__info {
        z-index: 2;
        background-color: #fff;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 16px 24px 24px 24px;

        .card__category {
          font-family: "Poppins", sans-serif;
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 2px;
          font-weight: 500;
        }

        .card__title {
          margin-top: 5px;
          margin-bottom: 10px;
          font-family: "Poppins", serif;
          color: var(--dark-color);
        }

        .card__by {
          font-size: 12px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: .5rem;

          .card__author {
            font-weight: 600;
            text-decoration: none;
            color: var(--yellow-color);
          }
        }
      }
    }
  }



}


.ant-pagination {
  margin-top: 50px !important;
  margin-bottom: 50px;
}

.ant-pagination-item-active {
  font-weight: 600;
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
}

.ant-pagination-item-active:hover a {
  color: white !important;
  ;
}

.ant-pagination-item-active a {
  color: white !important;
}