.main_services {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .container {

        .top_sec {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 120px;
            @media(max-width:1040px) {
             padding-top: 40px;
                }
            @media(max-width:770px) {
             flex-direction: column;
             align-items:flex-start;
           
                }
            .left_text {

                .page_title {
                    font-weight: 500;
                    margin-bottom: 12px;
                }

                .services_title {
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 1.3;
                    font-family: var(--font-inter);
                    color: var(--dark-color);
                    @media(max-width:1600px) {
                    font-size: 35px;      
                    }
                    @media(max-width:1040px) {
                    font-size: 30px;      
                    }
                }

            }


            .email {
                color: var(--dark-color);
                font-weight: 600;
                font-family: var(--font-inter);
                display: inline-block;
                position: relative;
                text-decoration: underline;
                transition: .3s ease-in-out;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }

        }

        .service_cardMain {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            // flex-wrap: wrap;
            gap: 48px;
            align-items: center;
            justify-content: center;
            padding-top: 40px;
            padding-bottom: 50px;

            @media(max-width:1600px) {
                gap: 30px;
            }

            @media(max-width:1300px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width:770px) {
                grid-template-columns: repeat(1, 1fr);

            }

            .service_card {
                // width: 383.83px;
                // height: 296px;
                position: relative;
                border: 1px solid rgba(30, 30, 30, .2);
                padding: 32px 40px 55px;
                border-radius: 4px;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;

                @media(max-width:1600px) {
                    padding: 40px;
                }

                @media(max-width:770px) {
                    padding: 25px;

                }

                &:hover {
                    transform: translateY(-5px);
                }

                .number {
                    font-family: var(--font-inter);
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(19, 15, 73, .5);
                    display: inline-block;
                    margin-bottom: 15px;
                }

                .card_title {
                    font-weight: 700;
                    line-height: 1.3;
                    font-family: var(--font-inter);
                    color: var(--dark-color);
                    font-size: 20px;
                    margin-bottom: 13px;
                }

                .card_para {
                    color: #55527c;
                    opacity: .7;
                    margin-bottom: 25px;
                }

                .read_more {
                    color: #130f49;
                    font-family: var(--font-inter);
                    font-weight: 500;
                    gap: 14px;
                    display: flex;
                    align-items: center;

                }

            }

        }

        .video_bg {
            width: 100%;
            max-height: 605px;
            overflow: hidden;
            transition: .3s ease-in-out;
            margin-bottom: 30px;
            position: relative;

            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 11;
                width: 111px;
                height: 111px;
                background-color: #fff;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @media(max-width:1040px) {
                    width: 84px;
                    height: 84px    
                    }
                    @media(max-width:770px) {
                        width: 54px;
                        height: 54px    
                        }
            }

            .into {
                position: absolute;
                bottom: 16px;
                right: 16px;
                z-index: 2;
                background-color: #fff;
                padding: 20px 30px;
                color: #51586a;
                font-size: 17px;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1.3;
                font-family: var(--font-inter);
                z-index: 1111;
                cursor: pointer;
                @media(max-width:1040px) {
                  font-size: 13px;
                    }
                @media(max-width:770px) {
                  font-size: 9px;
                  padding: 13px 15px;
                    }
            }

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .4);
                z-index: 11;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                    transition: .3s ease-in-out;
                }

            }

            img {
                width: 100%;
                height: 100%;
                transition: .3s ease-in-out;
                object-fit: contain;
            }
        }

    }

}