.wordCarousel {
  font-size: 64px;
  color: var(--dark-color);
  font-family: var(--font-karla);
  letter-spacing: 0;
  word-wrap: break-word;
  font-weight: 800;


  display: flex;
  justify-content: flex-start;
  align-items: center;

  >div {
    overflow: hidden;
    position: relative;
    float: right;
    height: 65px;
    padding-top: 10px;
    margin-top: -10px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      animation: flip3 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
    }

    li {
      color: var(--dark-color);
    font-family: var(--font-karla);
    font-weight: 800;
    padding: 0 10px;
    height: 45px;
    padding-bottom: 0px;
    margin-bottom: 44px;
    display: flex;
    align-items: center;
    }
  }
}

@keyframes flip3 {
  0% {
    margin-top: -450px;
  }

  5% {
    margin-top: -360px;
  }

  20% {
    margin-top: -360px;
  }

  25% {
    margin-top: -270px;
  }

  40% {
    margin-top: -270px;
  }

  45% {
    margin-top: -180px;
  }

  60% {
    margin-top: -180px;
  }

  65% {
    margin-top: -90px;
  }

  80% {
    margin-top: -90px;
  }

  85% {
    margin-top: 0px;
  }

  99.99% {
    margin-top: 0px;
  }

  100% {
    margin-top: -450px;
  }
}