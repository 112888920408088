#preloader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    display: flex;
}

@media (max-width: 1040px) {
    #preloader {
        display: none;
    }
}

#preloader:before,
#preloader:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s ease 0s;
}

#preloader:after {
    left: auto;
    right: 0;
}

#preloader .loader_line {
    margin: auto;
    width: 1px;
    height:100%;
    position: relative;
    overflow: hidden;
    transition: all 0.8s ease 0s;
}

.loader_line:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 0%;
    transform: translateY(-50%);
    background-color: #fff;
    animation: lineheight 1000ms ease-in-out 0s forwards;
}

.loader_line:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #999;
    transform: translateY(-100%);
    animation: lineround 1200ms linear 0s infinite;
    animation-delay: 2000ms;
}

@keyframes lineheight {
    0% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}

@keyframes lineround {

    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(200%);
    }
}

.preloaded .loader_line:after {
    opacity: 0;
}

.preloaded .loader_line {
    opacity: 0;
    height: 100% !important;
}

.preloaded:before,
.preloaded:after {
    animation: preloadedzero 1000ms ease-in-out 1000ms forwards;
}

@keyframes preloadedzero {
    0% {
        width: 50%;
    }

    100% {
        width: 0%;
    }
}

::-webkit-scrollbar {
    width: 11px;
}


::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: var(--blue-color);
    border-radius: 6px;
    border: 3px solid #fff;
}
