.main_contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    @media(max-width: 1040px){
       padding-top: 40px;
          }
    .container {


        .top_contact {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 1040px) {
                flex-direction: column;
                align-items: center;
                gap: 3rem;
            }
            @media(max-width: 600px){
               gap: 1.5rem;
                   }
            .left_contact {
                width: 50%;
                padding-right: 50px;

                @media(max-width: 1150px) {
                    padding-right: 20px;
                }

                @media(max-width: 1040px) {
                    width: 100%;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .port {
                    width: 100%;
                    font-weight: 500;
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 12px;
                }

                .port_title {
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 1.3;
                    font-family: var(--font-inter);
                    color: var(--dark-color);

                    @media(max-width: 1600px) {
                        font-size: 35px;
                    }
                }

                .cont_para {
                    margin-top: 20px;
                    margin-bottom: 40px;

                    @media(max-width: 1040px) {
                        margin-bottom: 20px;
                    }
                }

                .main_number {
                    display: flex;
                    flex-direction: column;

                    .number {
                        color: var(--dark-color);
                        font-weight: 600;
                        font-family: var(--font-inter);
                        display: inline-block;
                        position: relative;
                        margin-bottom: 8px;
                        width: fit-content;

                        &:hover::before {}

                        &::before {
                            :before {
                                position: absolute;
                                content: '';
                                width: 100%;
                                height: 1.5px;
                                left: 0;
                                bottom: 5px;
                                background-color: var(--dark-color);
                                transition: all .3s ease;
                            }
                        }
                    }
                }
            }

            .right_contact {
                width: 50%;
                padding-left: 50px;
                display: flex;
                flex-direction: column;
                gap: 25px;
                align-items: flex-start;
                justify-content: center;

                @media(max-width: 1150px) {
                    padding-left: 20px;
                }

                @media(max-width: 1040px) {
                    width: 100%;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                input {
                    width: 100%;
                    height: 60px;
                    background-color: #fff;
                    border-radius: 0;
                    font-size: 18px;
                    min-width: auto;
                    box-sizing: border-box;
                    font-family: var(--font-karla);
                    line-height: 1.6;
                    padding: 20px !important;
                    vertical-align: middle;
                    border: 1px solid #eee;
                    transition: all .3s ease;
                    font-family: "Poppins", sans-serif;
                    transition: .3s ease-in-out;
                    outline: none;

                    @media(max-width: 1600px) {
                        font-size: 16px;
                    }

                    &:focus {
                        outline: 1px solid #ccc;
                    }

                }

                textarea {
                    width: 100%;
                    height: 120px;
                    resize: none;
                    margin-bottom: 15px;
                    background-color: #fff;
                    border-radius: 0;
                    font-size: 18px;
                    resize: none;
                    font-family: "Poppins", sans-serif;
                    line-height: 1.4;
                    padding: 20px !important;
                    border: 1px solid #eee;
                    transition: all .3s ease;
                    outline: none;

                    @media(max-width: 1600px) {
                        font-size: 16px;
                    }
                    @media(max-width: 1040px){
                      margin-bottom: unset;
                            }

                    &:focus {
                        outline: 1px solid #ccc;
                    }
                }
            }
        }

        .bottom_contact {
            margin-top: 60px;
            margin-bottom: 40px;
            @media(max-width: 1040px){
               padding:0 10px;
               margin-top: 30px;
                  }
        }
    }

}