@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Kristi&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-color: #55527C;
  --dark-color: #130F49;
  --yellow-color: #FF972D;
  --blue-color: #A5A6FF;
  --white-color: white;
  --font-inter: 'Inter', sans-serif;
  --font-karla: 'Karla', sans-serif;
  --font-kristi: 'Kristi', cursive;
  --sign-color:rgba(19, 15, 73, .07)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ant-modal {
  width: 664px !important;
}

.ant-modal-close {
  display: none;
}

body {
  font-family: var(--font-karla);
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0;
  word-wrap: break-word;
  font-weight: 400;
  background-color: #fff;
  color: var(--main-color);


  @media (max-width: 1600px) {
    font-size: 16px;
  }
}

.App {
  display: grid;
  grid-template-columns: 369px 1fr;
  /* Sidebar and main content areas */
  min-height: 100vh;

  @media (max-width: 1600px) {
    grid-template-columns: 300px 1fr;
  }

  @media(max-width:1040px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navy {
    display: none;

    @media(max-width:1040px) {
      display: unset;
    }
  }


  .body {
    background-color: #effbf8;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
  }

  /* ::-webkit-scrollbar{
    display: none;
  } */

  .container {
    width: 100%;
    max-width: 1250px;
    padding-left: 20px;
    padding-right: 20px;

    @media(max-width:1600px) {
      max-width: 1000px;

    }

    @media(max-width:1040px) {
      max-width: unset;
    }
  }

  .siderBar {
    height: 100vh;
    border-right: 1px solid rgba(85, 82, 124, .1);

    @media(max-width:1040px) {
      display: none;
    }
  }

}